import React from "react";
import { IRegionProps } from "./RegionProps";
import { List } from "./RegionHelpers";
import { graphql, Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/global/Layout/Layout";
import SEO from "../../components/global/SEO/SEO";
import { getParagraph } from "../../utils/paragraphHelpers";
import { getAddressStateName } from "../../services/address";
import ParagraphLayout from "../../components/common/ParagraphLayout/ParagraphLayout";
import { Constants } from "../../@types/Constants";
import IParagraph from "../../@types/IParagraph";
import { cityHeader } from "./Region.module.scss";

const Region: React.FC<IRegionProps> = (props) => {
  const region = props.data.region;
  const state = props.data.allLocationCity.state[0];
  const cities = state?.cities;
  const countryCode = props.data.country?.code;
  const MetaTitle = region?.field_sonesta_meta_title;
  const MetaDescription = region?.field_sonesta_meta_description;
  const filterSonestaParagraphs = (paragraph: any) => {
    return paragraph?.relationships?.field_sites
      ? paragraph?.relationships.field_sites.machine_name === "sonesta"
        ? true
        : false
      : false;
  };
  const siteParagraphs = region?.relationships.paragraphs?.filter(
    filterSonestaParagraphs
  );
  const paragraphs = siteParagraphs ? siteParagraphs.map(getParagraph) : [];

  const _pageBanner = paragraphs.filter((p: IParagraph) => {
    return p
      ? p.props.paragraphtype === Constants.PARAGRAPH_PAGE_BANNER
      : false;
  });
  const belowSearchSiteParagraphs =
    region?.relationships.paragraphsBelowSearch?.filter(
      filterSonestaParagraphs
    );
  let belowSearchParagraph = belowSearchSiteParagraphs
    ? belowSearchSiteParagraphs.map(getParagraph)
    : [];
  belowSearchParagraph = belowSearchParagraph?.filter((ele: any) => {
    return ele !== null;
  });
  const belowSearchParagraphs = belowSearchParagraph.map(
    (item: { props: { node: any } }) => {
      if (item && item.props && item.props.node) {
        return {
          ...item,
          props: {
            ...item.props,
            node: {
              ...item.props.node,
              titelNodeType: "h2", // Adding a new property named "titelNodeType"
            },
          },
        };
      }
      return item;
    }
  );
  const stateTitle = region ? region.title : state?.title;
  const stateFullName =
    countryCode && getAddressStateName(countryCode, stateTitle);
  const title = stateFullName || stateTitle;
  return (
    <Layout showMerchandisingBlock={countryCode == "US"}>
      <SEO
        title={MetaTitle || `Hotels in ${title}`}
        description={MetaDescription || ""}
      />
      {_pageBanner}
      {paragraphs && paragraphs.length ? (
        <ParagraphLayout {...paragraphs} />
      ) : null}
      <Container className="px-0 pb-3 mb-5 mb-lg-5">
        {cities && (
          <Container className="mt-4" fluid="sm">
            <Row className="mx-3 mx-lg-0 ">
              <Col className="px-0">
                <h2 className={`${cityHeader} mt-3`}>{`${title} Hotels`}</h2>
                {cities.length ? (
                  <React.Fragment>
                    <List>
                      {cities.map((city: any) => {
                        const field_sites =
                          city.node.relationships?.field_sites || [];
                        const isHotelWithin25Miles =
                          city.node.field_hotels_with_in_25_miles.length > 0 &&
                          (city.node.field_hotels_with_in_25_miles.includes(
                            "31"
                          ) ||
                            city.node.field_hotels_with_in_25_miles.includes(
                              "1"
                            ));
                        return field_sites &&
                          isHotelWithin25Miles &&
                          (!field_sites.length ||
                            field_sites.some(
                              (site: { machine_name: string }) =>
                                site.machine_name === "sonesta" ||
                                site.machine_name === "redlion"
                            )) ? (
                          <li key={city.node.id}>
                            <Link
                              to={city.node.fields.path.alias}
                              title={city.node.name}
                            >
                              {city.node.field_address.locality}
                            </Link>
                          </li>
                        ) : null;
                      })}
                    </List>
                  </React.Fragment>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Container>
        )}
      </Container>
      {belowSearchParagraphs && belowSearchParagraphs.length ? (
        <ParagraphLayout {...belowSearchParagraphs} />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    region: nodeState(path: { alias: { eq: $slug } }) {
      title
      field_sonesta_meta_description
      field_sonesta_meta_title
      path {
        alias
      }
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphPageBanner
          ...ParagraphPageIntro
        }
        paragraphsBelowSearch: field_sections_below_search {
          type: __typename
          ...ParagraphPageTeaser
          ...ParagraphTeaserList
          ...ParagraphBody
          ...ParagraphFeaturedProperties
          ...ParagraphFrequentlyAskedQuestions
        }
      }
    }
    allLocationCity(
      sort: { order: ASC, fields: field_address___locality }
      filter: { fields: { path: { parent_alias: { eq: $slug } } } }
    ) {
      state: group(field: field_address___administrative_area) {
        title: fieldValue
        cities: edges {
          node {
            id
            name
            path {
              parent_alias
              alias
            }
            field_hotels_with_in_25_miles
            relationships {
              field_sites {
                machine_name
              }
            }
            fields {
              path {
                parent_alias
                alias
              }
            }
            field_address {
              administrative_area
              locality
              country_code
            }
          }
        }
      }
    }
    country: allLocationCity(
      filter: { fields: { path: { parent_alias: { eq: $slug } } } }
    ) {
      code: distinct(field: field_address___country_code)
    }
  }
`;

export default Region;
